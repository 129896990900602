import { getStateAtNamespaceKey } from 'redux/get-state-at-namespace-key';
import { createSelector } from 'reselect';

export const getSlots = (state: WtrState) => getStateAtNamespaceKey(state, 'slots').slots;

export const getSlotById = createSelector(
  [getSlots, (_: WtrState, { slotId }: { slotId: string }) => slotId],
  (slots = [], slotId) => slots.find(slot => slot.slotId === slotId),
);

export const isSlotsLoading = (state: WtrState) => getStateAtNamespaceKey(state, 'slots').loading;
export const getCurrentlyBookingSlotId = (state: WtrState) =>
  getStateAtNamespaceKey(state, 'slots').bookingSlotId;
export const isBookingSlot = (state: WtrState) => !!getCurrentlyBookingSlotId(state);

export const getAvailableSlots = createSelector([getSlots], slots =>
  slots.filter(slot => ['AVAILABLE', 'BOOKED'].includes(slot.slotStatus)),
);

export const bookedSlotIsGreen = createSelector(
  [getSlots, (state: WtrState) => getStateAtNamespaceKey(state, 'customerSlot')],
  (slots, customerSlot) => {
    const { slotDate, slotStartTime, slotEndTime } = customerSlot;
    return (
      slots.find(slot => {
        return (
          slot.date === slotDate &&
          slot.startDateTime.includes(slotStartTime) &&
          slot.endDateTime.includes(slotEndTime)
        );
      })?.greenSlot === true
    );
  },
);
